import React from 'react';
import {Link} from 'gatsby';
import centsToPriceNoTrailingZeros from 'magic-tricks/lib/centsToPriceNoTrailingZeros';

export default ({
	products = [],
	title,
	description,
	discountBadge,
	cents,
	collectionTileTitle,
	mainImage,
	mainImageLandscape,
}) => (
	<div className="mb5">
		<hr className="mb5 show--lg" />

		<div className="df fdc fdrr--lg jcb">
			<div className="search__image-col mb2 mb0--lg pr">
				<Link
					aria-label={title}
					to={`/cabinets/new?productIds=${products
						.map(p => p._id)
						.join(',')}`}
					className="db x">
					<div className="x image size--3x4 link--opacity show--lg">
						<picture>
							<img
								alt={title}
								src={`${mainImage.url}?w=500&h=666&auto=format`}
							/>
						</picture>
					</div>
					<div className="x image size--4x3 link--opacity hide--lg">
						<picture>
							<img
								alt={title}
								src={`${mainImage.url}?w=500&h=666&auto=format`}
							/>
						</picture>
					</div>
				</Link>
				<div className="pa p1 pen top left right df fdr jcb hide--lg">
					<p className="color--white serif--lg">
						{collectionTileTitle}
					</p>
					<p className="button--primary white ws--nowrap">
						{discountBadge}
					</p>
				</div>
			</div>

			<div className="search__content-col">
				<Link
					aria-label={title}
					className="link--trigger db mb2 hide--lg"
					to={`/cabinets/new?productIds=${products
						.map(p => p._id)
						.join(',')}`}>
					<p className="link--opacity fw--800">{title}</p>
					<p className="sans--xs sans--sm--lg">
						{products.map(product => product.title).join(', ')}
					</p>
				</Link>

				<Link
					aria-label={title}
					className="link--trigger db mb2 show--lg"
					to={`/cabinets/new?productIds=${products
						.map(p => p._id)
						.join(',')}`}>
					<p className="link--opacity serif--xxl mb1">{title}</p>
					<p>{products.map(product => product.title).join(', ')}</p>
				</Link>

				<p>
					<Link
						to={`/cabinets/new?productIds=${products
							.map(p => p._id)
							.join(',')}`}
						className="db button--pill--primary invert" aria-label="Shop Bundle">
						Shop Bundle {centsToPriceNoTrailingZeros(cents)}
					</Link>
				</p>
			</div>
		</div>
	</div>
);

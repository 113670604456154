import React from 'react';
import {Link} from 'gatsby';

export default ({title, description, url}) => (
	<div className="mb5">
		<hr className="mb5 show--lg" />

		<Link className="link--trigger db mb2" to={url} aria-label={title}>
			<p className="link--opacity serif--lg serif--xxl--lg mb1">
				{title}
			</p>
			<p>{description}</p>
		</Link>
	</div>
);

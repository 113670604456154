import React from 'react';
import {connectToStore} from './Provider';
import {encode} from '../utils/shopify-gid';
import ProductTileSearch from './ProductTileSearch';
import BundleTileSearch from './BundleTileSearch';
import PageTileSearch from './PageTileSearch';
import getWholesaleVariant from '../utils/getWholesaleVariant';
import reduce from 'lodash/reduce';
import getApp from 'magic-tricks/lib/getApp';

const SearchResultsGrid = ({
	results = [],
	isAddingItem,
	addItemAndOpenCart,
	customer,
	bulkDiscountMinimumItems,
	bulkDiscountPercentage,
}) => {
	const columns = results.map((item, index) => {
		if (item._type === 'product') {
			const discountVariant = getWholesaleVariant(
				item.variants,
				customer,
			);
			const cents = discountVariant.cents;
			const variantId = discountVariant.variantId;

			const onAdd = () => {
				const app = getApp();
				const {subscription} = item;
				const customAttributes = [];

				if (subscription) {
					customAttributes.push({
						key: '_subscription_options',
						value: JSON.stringify(subscription),
					});
				}

				const encodedVariantId = encode('ProductVariant', variantId, {
					accessToken:
						process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
				});

				// Track add to cart
				if (app.analytics) {
					app.analytics.addToCart({
						_productId: item.productId,
						id: discountVariant.sku,
						name: item.title,
						brand: 'Genexa',
						category: 'Medication',
						variant: discountVariant.title,
						quantity: 1,
						price: Number(discountVariant.price),
					});
				}

				return addItemAndOpenCart([
					{
						quantity: 1,
						variantId: encodedVariantId,
						customAttributes,
					},
				]);
			};

			return (
				<div key={item._key} className="col c6 c12--lg">
					<ProductTileSearch
						image={item.mainImage}
						title={item.title}
						cents={cents}
						slug={item.slug}
						drugName={item.drugName}
						activeIngredients={item.activeIngredients}
						isAdding={isAddingItem}
						onAdd={onAdd}
					/>
				</div>
			);
		} else if (item._type === 'bundle') {
			const totalCents = reduce(
				item.products,
				(totalCents, product) => {
					if (!product) return totalCents;

					if (item.products.length >= bulkDiscountMinimumItems) {
						const discountPercentage =
							(100 - bulkDiscountPercentage) / 100;
						return totalCents + product.cents * discountPercentage;
					} else {
						return totalCents + product.cents;
					}
				},
				0,
			);

			return (
				<div key={item._key} className="col">
					<BundleTileSearch
						products={item.products}
						title={item.title}
						description={
							item.searchMeta ? item.searchMeta.description : null
						}
						discountBadge={item.discountBadge}
						cents={totalCents}
						collectionTileTitle={item.collectionTileTitle}
						mainImage={item.mainImage}
						mainImageLandscape={item.mainImageLandscape}
					/>
				</div>
			);
		} else if (item._type === 'page') {
			return (
				<div key={item._key} className="col">
					<PageTileSearch
						title={item.title}
						description={
							item.searchMeta ? item.searchMeta.description : null
						}
						url={item.url}
					/>
				</div>
			);
		}

		return null;
	});

	return <div className="row">{columns}</div>;
};

const mapStateToProps = ({isAddingItem, customer}) => ({
	isAddingItem,
	customer,
});

const mapStoreToProps = ({addItemAndOpenCart}) => ({
	addItemAndOpenCart,
});

export default connectToStore(
	mapStateToProps,
	mapStoreToProps,
)(SearchResultsGrid);

import React, {useState} from 'react';
import classNames from 'classnames';
import {Link} from 'gatsby';
import centsToPriceNoTrailingZeros from 'magic-tricks/lib/centsToPriceNoTrailingZeros';
import getApp from 'magic-tricks/lib/getApp';

export default ({
	image = {url: ''},
	title,
	drugName,
	activeIngredients,
	cents,
	slug,
	isAdding,
	onAdd,
}) => {
	const [isAdded, setIsAdded] = useState(false);

	const onClickAdd = () => {
		onAdd().then(() => {
			setIsAdded(true);
		});
	};

	return (
		<div className="mb5">
			<hr className="mb5 show--lg" />

			<div className="df fdc fdrr--lg jcb">
				<div className="search__image-col mb2 mb0--lg">
					<Link to={`/products/${slug}`} className="db x" aria-label={title}>
						<div className="x image size--3x4 link--opacity">
							<picture>
								<img
									alt={title}
									src={`${image.url}?w=500&h=666&auto=format`}
								/>
							</picture>
						</div>
					</Link>
				</div>

				<div className="search__content-col">
					<Link
						className="link--trigger db mb2 hide--lg"
						to={`/products/${slug}`} aria-label={title}>
						<p className="link--opacity fw--800">{title}</p>
						<p className="fw--800 sans--xs">{drugName}</p>
						<p className="sans--xs">{activeIngredients}</p>
					</Link>

					<Link
						className="link--trigger db mb2 show--lg"
						to={`/products/${slug}`} aria-label={title}>
						<p className="link--opacity serif--xxl mb1">{title}</p>
						<p className="fw--800">{drugName}</p>
						<p>{activeIngredients}</p>
					</Link>

					<button
						title={isAdded ? 'Added to Bag' : 'Add to Bag - '+ centsToPriceNoTrailingZeros(cents)}
						aria-label={isAdded ? 'Added to Bag' : 'Add to Bag - '+ centsToPriceNoTrailingZeros(cents)}
						onClick={onClickAdd}
						disabled={isAdding}
						className={classNames('button--pill--primary invert', {
							loading: isAdding,
						})}>
						{isAdded ? (
							'Added'
						) : (
							<span>
								<span>{'Add'}</span>
								<span className="show--md"> to Bag</span>
								<span>
									{' - '}
									{centsToPriceNoTrailingZeros(cents)}
								</span>
							</span>
						)}
					</button>
				</div>
			</div>
		</div>
	);
};

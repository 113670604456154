import React, {useState, useEffect} from 'react';
import {navigate} from 'gatsby';
import Seo from '../components/Seo';
import Fuse from 'fuse.js';
import queryString from 'query-string';
import InlineForm from '../components/InlineForm';
import SearchResultsGrid from '../components/SearchResultsGrid';
import pluralize from 'magic-tricks/lib/pluralize';
import Helmet from 'react-helmet';

const QUERY_OPTIONS = {
	keys: [
		'title',
		'searchMeta.description',
		'searchMeta.keywords',
		'drugName',
		'activeIngredients',
		'products.title',
		'products.drugName',
		'products.activeIngredients',
		'products.searchMeta.description',
		'products.searchMeta.keywords',
	],
	threshold: 0.6,
	isCaseSensitive: false,
	shouldSort: true,
	includeScore: false,
	// includeMatches: false,
	// findAllMatches: false,
	// minMatchCharLength: 1,
	// location: 0,
	// distance: 100,
	// useExtendedSearch: false,
	// ignoreLocation: false,
	// ignoreFieldNorm: false,
};

export default ({pageContext, location}) => {
	const {seo = {}, header, results = [], cart = {}} = pageContext;
	const {bulkDiscountMinimumItems, bulkDiscountPercentage} = cart;
	const [query, setQuery] = useState('');
	const [pendingQuery, setPendingQuery] = useState('');

	useEffect(() => {
		const queryParams = queryString.parse(location.search);

		if (queryParams && queryParams.query) {
			setQuery(queryParams.query);
			setPendingQuery(queryParams.query);
		}
	}, [location.search]);

	const fuse = new Fuse(results, QUERY_OPTIONS);
	const filteredResults = fuse.search(query).map(i => i.item);

	const metaTitle = seo.metaTitle || 'Search Results - Genexa';
	const openGraphTitle = seo.openGraphTitle || 'Search Results - Genexa';
	const twitterTitle = seo.twitterTitle || 'Search Results - Genexa';

	const searchHeader = (
		<div className="mt3 mt10--md mb5 mb12--md grid-container contained">
			<h1 className="mb4 serif--xl serif--xxxl--md tc">Search Results</h1>
			{query && (
				<p className="mt4 mb4 tc">{`Showing results for “${query}”`}</p>
			)}
			<div className="row align--middle">
				<div className="col c3--lg c4--xl" />
				<div className="col c6--lg c4--xl">
					<InlineForm
						initialValue={pendingQuery}
						onSubmit={query => {
							navigate(
								`/search?query=${encodeURIComponent(query)}`,
							);
						}}
						submitCta="Search"
						placeholder={header.searchPlaceholder}
					/>
				</div>
				<div className="col c3--lg c4--xl">
					<div className="df fdr jcc jce--lg mt5 mt0--lg">
						{filteredResults.length > 0 &&
							`${filteredResults.length} ${pluralize(
								'result',
								filteredResults.length,
							)}`}
					</div>
				</div>
			</div>
		</div>
	);

	const emptyState = filteredResults.length === 0 && query && (
		<div className="grid-container contained mt10 mb10">
			<div className="row align--center">
				<div className="col c10--md c8--lg">
					<picture className="db x">
						<img className="db x" src="/images/search-empty.jpg" alt="Search" />
					</picture>
				</div>
			</div>
		</div>
	);

	return (
		<React.Fragment>
			<Helmet title="Search - Genexa" />
			<Seo
				metaTitle={metaTitle}
				metaDescription={seo.metaDescription}
				metaKeywords={seo.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo.openGraphDescription}
				openGraphImage={seo.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo.twitterDescription}
				twitterImage={seo.twitterImage}
				pathname={location.pathname}
			/>
			{searchHeader}
			{emptyState}

			{!!filteredResults.length && (
				<div className="grid-container contained">
					<div className="row align--center">
						<div className="col c10--lg">
							<SearchResultsGrid
								results={filteredResults}
								bulkDiscountMinimumItems={
									bulkDiscountMinimumItems
								}
								bulkDiscountPercentage={bulkDiscountPercentage}
							/>
							<hr className="mt6 mt12--lg" />
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	);
};
